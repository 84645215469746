<template>
  <table class="text-center table-print b-table table-bordered custom-table">
    <template v-for="(item, index) in summary">
      <!-- Cabeçalho da página -->
      <tr :key="index" class="header" v-if="index === 0 || index % 6 === 0">
        <th style="background-color: #02243f">
          <img
            src="../assets/images/logo-nova.png"
            alt="HOFácil"
            style="width: 179px"
          />
        </th>
        <td colspan="8">
          Relatório de impressão gerado do software de Higiene Ocupacional para
          agentes químicos HO Fácil Web. As informações aqui mostradas são
          relativas às pesquisas exibidas na aba Relatório.
        </td>
      </tr>

      <!-- corpo da página -->
      <tr :key="`searchId_${item.legalSearchId}`">
        <th>CAS: Agente</th>
        <td colspan="8">{{ model.getValue('searchId', item) }}</td>
      </tr>
      <tr :key="`nr15_${item.legalSearchId}`">
        <th rowspan="2">ACGIH 2024</th>
        <th colspan="6">NR 15</th>
        <th>LINACH</th>
        <th rowspan="2">ESOCIAL - 24</th>
      </tr>
      <tr :key="`nr15_subtitles_${item.legalSearchId}`">
        <th>Fundamentação</th>
        <th>Teto</th>
        <th>Pele</th>
        <th>mg/m³</th>
        <th>ppm</th>
        <th>Insalubridade</th>
        <th>Grupo</th>
      </tr>
      <tr :key="`nr15_content_${item.legalSearchId}`">
        <td>{{ model.getValue('reference', item) }}</td>
        <template v-if="model.getValue('nr15-base', item) === '-'">
          <td>{{ model.getValue('nr15-base', item) }}</td>
          <td>{{ model.getValue('ceil', item) }}</td>
          <td>{{ model.getValue('skinAbsorption', item) }}</td>
          <td>{{ model.getValue('mgm', item) }}</td>
          <td>{{ model.getValue('ppm', item) }}</td>
          <td>{{ model.getValue('insalubrity', item) }}</td>
        </template>
        <template v-else>
          <td colspan="6">{{ model.getValue('nr15-base', item) }}</td>
        </template>
        <td>{{ model.getValue('group', item) }}</td>
        <td>{{ model.getValue('esocial', item) }}</td>
      </tr>
      <tr :key="`decreto_${item.legalSearchId}`">
        <th rowspan="3">Decreto 3048/99 - Anexo IV</th>
        <td></td>
      </tr>
      <tr :key="`decreto_group_${item.legalSearchId}`">
        <th rowspan="1">Grupo:</th>
        <td colspan="7">{{ model.getValue('decree3048', item) }}</td>
      </tr>
      <tr :key="`decreto_condition_${item.legalSearchId}`">
        <th>Condições:</th>
        <td colspan="7">{{ model.getValue('condition', item) }}</td>
      </tr>
      <tr :key="`space_${item.legalSearchId}`">
        <td colspan="9">
          <br />
        </td>
      </tr>

      <!-- Rodapé com número de páginas -->
      <tr :key="index + '_br'" v-if="index != 0 && (index + 1) % 6 === 0">
        <td colspan="10">
          <br v-for="i in 7" :key="i" />
          {{ Math.ceil(index / 6) }}
        </td>
      </tr>
      <!-- Rodapé com número de páginas da última página caso não esteja completa com 6 items -->
      <tr :key="index + '_end_br'" v-else-if="index + 1 === summary.length">
        <td colspan="10">
          <br v-for="i in distanceItemAndFooter(summary.length)" :key="i" />
          {{ Math.ceil(index / 6) }}
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import LegalSearchReport from '@/models/LegalSearchReport'

export default {
  data() {
    return {
      model: LegalSearchReport,
      selectedAgent: {},
    }
  },
  computed: {
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
  async mounted() {
    if (this.summary.length === 0) {
      this.$router.push({ name: 'LegalSearch' })
    } else {
      await this.$store.dispatch('userData/updatePrintedReports', this.user.id)
      window.print()
    }
  },
  methods: {
    distanceItemAndFooter(length) {
      const itemsMissing = Math.ceil(length / 6) * 6 - length
      switch (itemsMissing) {
        case 5:
          return 48
        case 4:
          return 40
        case 3:
          return 31
        case 2:
          return 23
        case 1:
          return 15
        case 0:
          return 7
      }
    },
  },
}
</script>

<style scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}

.table-print {
  width: 100vw;
}
img {
  width: 179px;
}
.table-print tr th {
  border: 2px solid #000;
}

.table-print tr th {
  background-color: var(--sapphire-blue);
  color: var(--white);
}

.table-print tr th,
.table-print tr td {
  border: 1px solid #000;
  padding: 0px 10px;
}

.table-print tr.header th {
  background-color: var(--white);
}

.table-print tr.header td {
  color: var(--white);
  background-color: var(--sapphire-blue);
  font-weight: 700;
  padding: 15px;
}
</style>
